import React from 'react'
import PropTypes from 'prop-types'

import SEO from 'seo/page'
import { Full as Template } from 'components/Templates'
import { Hero, CTA } from 'components/Organisms/Home'
import { getAppName } from 'models/application'

const Screen = ({ application, loadingPage }) => (
  <>
    <Template application={application} loading={loadingPage}>
      <SEO title={`How to Park Using Citifyd | ${getAppName(application)}`} />
      <Hero />
      <CTA application={application} />
    </Template>
  </>
)

Screen.propTypes = {
  application: PropTypes.string,
  loadingPage: PropTypes.bool,
}

export default Screen
