import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MdClose } from 'react-icons/md'

const Component = styled.div`
  position: absolute;
  width: 100%;
  height: 300px;
  z-index: 100;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 470px;
  }
`

const Player = styled.iframe`
  display: flex;
  margin: 0 auto;
  height: 100%;
  width: 100%;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: ${props => props.theme.palette.black};
  background-color: rgba(255, 255, 255, 0.9);
  border: none;
  z-index: 110;

  &:hover {
    background-color: ${props => props.theme.palette.white};
  }
`

const CloseButtonIcon = styled(MdClose)`
  width: 24px;
  height: 24px;
`

const VideoPlayer = ({ onClose }) => (
  <Component>
    <Player
      src="https://www.youtube-nocookie.com/embed/fPcA5Nn-L5Y?autoplay=1&rel=0"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
    <CloseButton onClick={onClose}>
      <CloseButtonIcon />
    </CloseButton>
  </Component>
)

VideoPlayer.propTypes = {
  onClose: PropTypes.func,
}

export default VideoPlayer
