import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import { withRouter } from 'next/router'

import ButtonComponent from 'components/Atoms/Button'
import Text from 'components/Atoms/Text'
import { Link } from 'i18n-web/i18next'
import { getAppRoute } from 'helpers/application'
import { useClientResponsive } from 'helpers/hooks'
import { useTranslation } from 'i18n-web/i18next'

const Component = styled.section`
  position: relative;
  padding: 35px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.theme.palette.background};

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    padding: 70px 0;
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h1};
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  line-height: 1.1;
  color: ${props => props.theme.palette.grayDark};
`

const Description = styled(Text)`
  margin: 10px 0 30px;
  text-align: center;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 15px;
  }
`

const Button = styled(ButtonComponent)`
  padding: 0;
  width: 100%;
  height: 50px;
  color: ${props => props.theme.palette.grayDark};
  text-transform: uppercase;
  font-size: ${props => props.theme.font.size.mobile.h3};
  font-weight: 400;
  line-height: 1;
  background-color: ${props => props.theme.palette.primary};
  border: solid 4px ${props => props.theme.palette.primary};

  & + & {
    margin-top: 20px;
  }

  &:hover {
    background-color: ${props => props.theme.palette.background};
    opacity: 1;
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 70px;
    font-size: ${props => props.theme.font.size.desktop.h3};

    :not(.full) {
      margin-top: 0;
      width: calc(50% - 15px);
    }
  }
`

const CTA = ({
  application,
  onReservedButtonClick,
  onMonthlyButtonClick,
  onHourlyClick,
}) => {
  const { t } = useTranslation()
  const { isMobile } = useClientResponsive()

  return (
    <Component>
      <Grid>
        <Row center="xs">
          <Col xs={12} lg={9}>
            <Title as="h2">{t('home.cta.title')}</Title>
            <Description>{t('home.cta.description')}</Description>
            <ButtonGroup>
              <Link
                as={`${getAppRoute(application, 'as')}/events`}
                href={`${getAppRoute(application, 'url')}/events`}>
                <Button>{t('home.cta.buttons.events')}</Button>
              </Link>
              <Button onClick={onReservedButtonClick} type="button">
                {isMobile
                  ? t('home.cta.buttons.reservedParking')
                  : t('home.cta.buttons.reserved')}
              </Button>
              <Button onClick={onMonthlyButtonClick} type="button">
                {t('home.cta.buttons.monthly')}
              </Button>
              {isMobile && (
                <Button onClick={onHourlyClick} type="button">
                  {t('hourly.button')}
                </Button>
              )}
              <Link
                as={`${getAppRoute(application, 'as')}/my-passes`}
                href={`${getAppRoute(application, 'url')}/my-passes`}>
                <Button className="inverted">
                  {t('home.cta.buttons.passesAndReceipts')}
                </Button>
              </Link>
            </ButtonGroup>
          </Col>
        </Row>
      </Grid>
    </Component>
  )
}

CTA.propTypes = {
  application: PropTypes.string,
  onReservedButtonClick: PropTypes.func,
  onMonthlyButtonClick: PropTypes.func,
  onHourlyClick: PropTypes.func,
}

export default withRouter(CTA)
