import { useDispatch } from 'react-redux'

import { resetReservedParking } from 'redux-web/utils/reservedParking/actions'
import { resetMonthlyParking } from 'redux-web/utils/monthly/actions'
import { resetSearch as resetHourlySearch } from 'redux-web/utils/hourlySearch/actions'
import { showModal } from 'components/Atoms/Modal/actions'
import { useClientResponsive } from 'helpers/hooks'

import CTA from './Screen'

const CTAHOC = props => {
  const { isMobile } = useClientResponsive()
  const dispatch = useDispatch()

  const commonModalProps = {
    fullScreen: false,
    modalProps: {
      className: 'noBorders',
      customStyle: {
        alignSelf: 'flex-start',
        backgroundColor: 'transparent',
        top: isMobile ? '58px' : '200px',
        width: isMobile ? '100%' : '590px',
        ...(isMobile && { padding: '0 20px !important' }),
      },
      hideCloseMobile: true,
      noPadding: true,
      open: true,
      preventCloseOnBackgroundClick: false,
    },
  }

  const onReservedButtonClick = () => {
    dispatch(resetReservedParking())
    dispatch(
      showModal({
        ...commonModalProps,
        modalType: 'reservedParkingSearch',
      })
    )
  }

  const onMonthlyButtonClick = () => {
    dispatch(resetMonthlyParking())
    dispatch(
      showModal({
        ...commonModalProps,
        modalType: 'monthlyParkingSearch',
      })
    )
  }

  const onHourlyClick = () => {
    dispatch(resetHourlySearch())
    dispatch(
      showModal({
        ...commonModalProps,
        modalType: 'hourlyParkingSearch',
      })
    )
  }

  return (
    <CTA
      onReservedButtonClick={onReservedButtonClick}
      onMonthlyButtonClick={onMonthlyButtonClick}
      onHourlyClick={onHourlyClick}
      {...props}
    />
  )
}

export default CTAHOC
