import React, { useState } from 'react'
import styled from 'styled-components'
import { Grid, Col, Row } from 'react-styled-flexboxgrid'
import ReactRotatingText from 'react-rotating-text'
import classNames from 'classnames'

import Text from 'components/Atoms/Text'
import { useTranslation } from 'i18n-web/i18next'

import VideoPlayer from './VideoPlayer'

const Component = styled.section`
  position: relative;
  background-image: url('/static/images/home/hero-mobile.jpg');
  background-position: center;
  background-size: cover;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(52, 52, 52, 0.6);
  }

  &.dark:before {
    background-color: rgba(0, 0, 0, 0.8);
  }

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    background-image: url('/static/images/home/hero.jpg');
  }
`

const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 300px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 470px;
  }
`

const Title = styled(Text)`
  font-size: ${props => props.theme.font.size.mobile.h1};
  font-weight: 800;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  color: ${props => props.theme.palette.primary};
  z-index: 10;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    font-size: ${props => props.theme.font.size.desktop.h2};
  }
`

const RotatingTextWrapper = styled.div`
  height: 55px;

  @media (min-width: ${props => props.theme.flexboxgrid.breakpoints.sm}rem) {
    height: 60px;
  }
`

const PlayButton = styled.button`
  margin-top: 20px;
  background: none;
  border: 0;
  z-index: 10;
`

const PlayIcon = styled.img`
  width: 40px;
  height: 40px;
`

const Hero = () => {
  const [showPlayer, setShowPlayer] = useState(false)

  const { t } = useTranslation()

  const handleOnClick = () => setShowPlayer(true)

  const handleOnClose = () => setShowPlayer(false)

  return (
    <Component
      className={classNames({
        dark: !!showPlayer,
      })}>
      <Grid>
        <Row>
          <Col xs={12}>
            <Content>
              {showPlayer && <VideoPlayer onClose={handleOnClose} />}
              <Title as="h2">
                {t('home.hero.title')}
                <br />
                <RotatingTextWrapper>
                  <ReactRotatingText
                    cursor={false}
                    deletingInterval={100}
                    emptyPause={500}
                    items={[
                      t('home.hero.sentences.0'),
                      t('home.hero.sentences.1'),
                      t('home.hero.sentences.2'),
                      t('home.hero.sentences.3'),
                    ]}
                    pause={2500}
                    typingInterval={100}
                  />
                </RotatingTextWrapper>
              </Title>
              <PlayButton onClick={handleOnClick}>
                <PlayIcon src={'/static/icons/home/icon-play.svg'} />
              </PlayButton>
            </Content>
          </Col>
        </Row>
      </Grid>
    </Component>
  )
}

export default Hero
